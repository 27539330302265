<template>
  <v-card min-height="100vh" outlined flat class="rounded-10 overflow-hidden">
    <v-card-title style="min-height: 300px" class="primary d-block rounded-0">
      <v-row dense align="center" justify="space-between">
        <v-breadcrumbs light :items="paths">
          <template v-slot:divider>
            <v-icon color="white">mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              @click="$router.push({ path: item.href })"
              :disabled="item.disabled"
            >
              <span class="white--text cursor-pointer">{{ item.text }}</span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <div>
          <v-btn
            @click="$router.go(-1)"
            small
            depressed
            class="text-none primary--text"
            color="white"
          >
            <v-icon color="primary" left>mdi-chevron-left</v-icon> Back
          </v-btn>
          <v-btn
            v-if="canManageCourse"
            @click="editCourse"
            small
            depressed
            class="text-none ml-1 white--text"
            color="accent"
          >
            <v-icon color="white" left>mdi-pencil</v-icon> Edit Course
          </v-btn>
          <v-btn
            v-if="canManageCourse"
            @click="deleteCourse"
            small
            depressed
            class="text-none ml-1 white--text"
            color="red"
          >
            <v-icon color="white" left>mdi-delete</v-icon> Delete Course
          </v-btn>
        </div>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="8" sm="12" class="d-flex pa-5 flex-column">
          <h1 class="white--text">{{ course.title | ucwords }}</h1>
          <div
            class="body-1 white--text"
            :inner-html.prop="course.contents"
          ></div>
          <div class="d-flex flex-column">
            <span class="body-2 white--text">
              Created by : {{ course.user.fullname | ucwords }}
            </span>
            <span class="body-2 white--text">
              Last updated : {{ course.updated_at | format('LLL') }}
            </span>
            <span class="body-2 white--text">
              Language : {{ course.language | ucwords }}
            </span>
            <span class="body-2 white--text">
              Requirements : {{ course.requirements || 'None' }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          class="d-flex align-center justify-center"
        >
          <v-img
            max-height="200"
            :src="
              course.preview
                ? course.preview.url_inline
                : settings.noImagePreview
            "
            contain
          ></v-img>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pa-md-7 pa-sm-5">
      <v-row v-if="course" dense align="stretch" justify="start">
        <v-col cols="12" md="8" class="mx-auto" sm="12">
          <v-sheet v-for="step in course.episodes" :key="step.id">
            <strong class="fs-17 fw-500 secondary--text">
              {{ step.title }}
            </strong>
            <div class="body-2" v-html="step.contents"></div>
          </v-sheet>
        </v-col>
      </v-row>
      <Empty
        v-else-if="!course"
        min-height="100vh"
        :headline="`No course found`"
      ></Empty>
    </v-card-text>
  </v-card>
</template>

<script>
import request from '@/services/axios_instance'
import { fetch_services } from '@/services/fetch/fetch_services'
import EachCourse from './components/EachCourse.vue'
import { mapGetters } from 'vuex'
export default {
  components: { EachCourse },
  data: () => ({
    course: null
  }),
  mixins: [fetch_services],
  watch: {
    '$route.params.slug': {
      handler: function (val) {
        this.fetchCourse(val)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    canManageCourse() {
      return this.user.is_super_admin
    },
    paths() {
      let x = [
        {
          text: 'Courses',
          disabled: false,
          href: '/dashboard/courses'
        }
      ]
      if (this.course) {
        x.push({
          text: this.course.maincategory.title,
          disabled: false,
          href: '/dashboard/courses/' + this.course.maincategory.slug
        })
        x.push({
          text: this.course.subcategory.title,
          disabled: false,
          href: `/dashboard/courses/${this.course.maincategory.slug}/${this.course.subcategory.slug}`
        })
      }
      return x
    }
  },
  methods: {
    fetchCourse(slug) {
      request.get(`api/courses/${slug}`).then(({ data }) => {
        this.course = data
      })
    },
    editCourse() {
      this.$router.push({
        name: 'create-update-series',
        params: { id: this.course.id }
      })
    },
    deleteCourse() {
      this.appConfirmation(
        `Delete this course?`,
        () => {
          request
            .delete(`api/courses/series/${this.course.id}`)
            .then(({ data }) => {
              this.$router.push({ name: 'courses' })
            })
        },
        null,
        'Yes! Delete.'
      )
    }
  }
}
</script>

<style></style>
